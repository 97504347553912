<template>
<div class="col-md-8 mx-auto">
  <div class="card rounded shadow-lg">
    <div class="card-body">
      <div class="card-title text-center">
        <h1>Usuarios por sala</h1>
      </div>
      <br>
      <div class="row">
        <div class="col-md-12">
          <div>
            <form>
              <div class="">
                <button
                  type="button"
                  v-on:click="newSearch()"
                  class="btn btn-primary"
                  data-toggle="modal"
                  data-target="#searchModal"
                >Búsqueda avanzada</button>
              </div>
              <searchusuxsal v-on:search="filtrarItems" v-bind:item="search"></searchusuxsal>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <button v-on:click="$router.push({ name: 'CreateUsuxsal', params: {add: true} });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear Usuario por sala</button>
          </div>
          <br>
          <div v-if="filter" class="alert alert-primary" role="alert">
            {{ keywords }}
            <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
          </div>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="list-doc" role="tabpanel" aria-labelledby="list-doc-list">
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Usuario</td>
                      <td>Sala</td>
                      <td>Modificar</td>
                      <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items">
                      <td class="text-center">
                        <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>
                      </td>
                      <td v-on:click="editItem(item._id)" onmouseover="this.style.cursor='pointer'">{{ item.usuario_usuxsals[0] != undefined ? item.usuario_usuxsals[0].usu_nmbre + ' ' + item.usuario_usuxsals[0].usu_aplldo : '' }}</td>
                      <td>{{ item.sala_usuxsals[0] != undefined ? item.sala_usuxsals[0].sal_nmbre : '' }}</td>
                      <td>{{ item.uxs_mdfcr | formatBoolean }}</td>
                      <td class="text-center">
                        <input type="checkbox" v-bind:value="item._id" v-model="delItems">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane fade" id="list-doc-archivo" role="tabpanel" aria-labelledby="list-archivo-list">...</div>
          </div>
          <br>
          <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
            <i data-feather="delete"></i>Eliminar
          </button>
          <!--{{delItems}}-->
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import { formatBoolean } from './../../plugins/filters';
import SearchUsuxsal from "./SearchUsuxsal.vue";

export default {
  components: {
    //VIcon,
    searchusuxsal: SearchUsuxsal
  },
  data(){
    return{
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      showProgress: false,
      filter: false, // Items are filtered
    }
  },
  filters: {
    formatBoolean
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    this.fetchItems();
  },
  methods: {
    fetchItems()
    {
      let uri = '/uxs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.items = response.data;
        this.delItems = [];
      });
    },
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/uxs/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    deleteItems()
    {
      if (this.delItems.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (var i = 0; i < this.delItems.length; i++){
            //alert('Item(' + i + '): ' + this.delItems[i]);
            let uri = '/uxs/delete/' + this.delItems[i];
            this.axios.get(uri)
            .then(res => {
              //this.items.splice(index, 1);
            })
            .catch(err => {
              console.log(err);
              for (var m = 0; m < this.items.length; m++){
                if (this.delItems[i] == this.items[m]._id){
                  alert('No se ha podido eliminar el item ' + this.items[m]);
                  m = this.items.length;
                }
              }
            });
          }
          if (this.filter){
            this.searchItems(this.keywords);
          } else {
            this.fetchItems();
          }
        }
      }
      return;
    },
    checkAllItems(){
      this.delItems = [];
      if (!this.allChecked){
        for (var i = 0; i < this.items.length; i++){
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    newSearch() {
      this.search = {};
    },
    filtrarItems(p){
      this.searchItems(p);
    },
    searchItems(p) {
      console.log(p);
      if (p != null) {
        this.showProgress = true;

        p.cmp_id = String(this.$store.state.company);
        this.message = "Buscando permisos";
        let uri = "/uxs/search";
        this.axios.post(uri, p)
        .then(response => {
          this.items = response.data;
          
          //this.search = p;
          this.showProgress = false;
          this.delItems = [];          
        })
        .catch(err => {
          this.showProgress = false;
          this.message = "¡Error al buscar los permisos" + err;
        });
      }
    },
    closeSearch(){
      this.filter = false;
      this.keywords = '';
      this.fetchItems();
    },
    createItem(){
      this.$router.push({ name: 'CreateUsuxsal', params: {add: true} });
    },
    editItem(id){
      this.$router.push({ name: 'CreateUsuxsal', params: {id: id, add: false} });
    }
  }
}
</script>
