<template>
  <!-- Modal -->
  <div class="modal" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <form>
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Búsqueda avanzada de permisos de sala</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Usuario (*):</label>
                <select id="usu_id" v-model="item.usu_id" class="form-control" style="width: 100%;" required>
                  <option v-for="usuario in usuarios" v-bind:value="usuario.id">
                    {{ usuario.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Sala (*):</label>
                <select id="sal_id" v-model="item.sal_id" class="form-control" style="width: 100%;" required>
                  <option v-for="sala in salas" v-bind:value="sala.id">
                    {{ sala.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-3">
                <label>Modificar (*):</label>
                <input type="checkbox" class="form-control col-md-8" v-model="item.uxs_mdfcr">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="submit" class="btn btn-primary" data-dismiss="modal" v-on:click="search()">Buscar permisos</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.min.css';

//import Select2 from '../select2/Select2.vue';

export default {
  //name: 'SearchUsuxsal',
  components: {
    //Select2
  },
  props: ['item'],
  data() {
    return {
      usuarios: [],
      salas: []
    }
  },
  mounted: function() {
    $(document).ready(function() {
      $('select').select2({
        dropdownParent: $('#searchModal')
      });
            
    });
  },
  created: function() {
    this.fetchUsuario();
    this.fetchSala();
  },
  destroyed: function () {
    $(this.$el).find('select').select2('destroy');
  },
  methods: {
    fetchUsuario(){
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.usu_nmbre + ' ' + a.usu_aplldo + ' (' + a.usu_cdgo + ')').toLowerCase(); 
          var tb = String(b.usu_nmbre + ' ' + b.usu_aplldo + ' (' + b.usu_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.usuarios = list.map(i => ({ id: String(i._id), text: i.usu_nmbre + ' ' + i.usu_aplldo + ' (' + i.usu_cdgo + ')' }));
      });
    },
    fetchSala(){
      let uri = '/salas/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        var list = response.data;
        list.sort(function(a,b){
          var ta = String(a.sal_nmbre + ' (' + a.sal_cdgo + ')').toLowerCase(); 
          var tb = String(b.sal_nmbre + ' (' + b.sal_cdgo + ')').toLowerCase(); 
          if (ta > tb) { return 1; };
          if (ta < tb) { return -1; };
          return 0;
        });
        this.salas = list.map(i => ({ id: String(i._id), text: i.sal_nmbre + ' (' + i.sal_cdgo + ')' }));
      });
    },
    search(){
      if ($('#usu_id').val())
        this.item.usu_id = $('#usu_id').val();
      if ($('#sal_id').val())
        this.item.sal_id = $('#sal_id').val();

      this.$emit('search', this.item);      
      $(this.$el).find('select').val(null).trigger('change');

    }
  } // END METHODS
}
</script>

<style>
/*
.modal-dialog {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width:500px;
    height:300px;
}
*/

  .select2-container .select2-selection--single {
      box-sizing: border-box;
      border: 1px solid #ced4da;
      cursor: pointer;
      display: block;
      height: 40px;
      user-select: none;
      -webkit-user-select: none;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
      color: #444;
      line-height: 40px;
  }    
</style>
